@import '@/styles/styles';

@mixin dark-menu {
  .logo {
    svg {
      path {
        fill: $text-color-primary-dark;
      }
    }
  }

  .items {
    color: $text-color-primary-dark;
  }

  .hamburgerLines {
    .line {
      background: $text-color-primary-dark;
    }
  }
}

@mixin light-menu {
  .logo {
    svg {
      path {
        fill: $text-color-primary-light;
      }
    }
  }

  .left {
    color: $text-color-primary-light;
  }

  .hamburgerLines {
    .line {
      background: $text-color-primary-light;
    }
  }
}

.container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  left: 0;
  padding: 20px 32px;
  position: absolute;
  top: 0;
  transition: background-color 0.3s ease;
  width: 100%;
  z-index: 999;

  &.fixed {
    position: fixed;
  }

  .logo {
    max-width: 175px;
    z-index: 1;

    @include media-breakpoint-down(lg) {
      max-width: 120px;
    }

    svg {
      width: 100%;

      path {
        transition: all 0.3s ease;
      }
    }

    .items {
      transition: color 0.3s ease;
    }
  }

  .items {
    align-items: center;
    color: $white;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: 700;
    justify-content: space-between;
    line-height: 20px;
    text-transform: uppercase;
    transition: color 0.3s ease;

    .item {
      color: inherit;
      cursor: pointer;
      position: relative;
      text-decoration: none;

      &:not(:last-child) {
        margin-right: 24px;
      }

      &::after {
        background-color: $primary;
        border-radius: 2px;
        bottom: -4px;
        content: '';
        height: 2px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        transition: width 0.3s ease;
        width: 0;
      }

      &:hover {
        &::after {
          width: 80%;
        }
      }

      &.active {
        &::after {
          width: 100%;
        }
      }
    }
  }

  .hamburgerLines {
    align-items: flex-end;
    cursor: pointer;
    display: none;
    flex-direction: column;
    height: 18px;
    justify-content: space-between;
    width: 32px;
    z-index: 1;

    .line {
      background: $white;
      border-radius: 10px;
      display: block;
      height: 2px;
      width: 100%;

      &.line1 {
        transform-origin: center left;
        transition: transform 0.4s ease-in-out, width 0.3s ease, background 0.3s ease;
      }

      &.line2 {
        transition: transform 0.2s ease-in-out, background 0.3s ease;
      }

      &.line3 {
        transform-origin: center left;
        transition: transform 0.4s ease-in-out, width 0.3s ease, background 0.3s ease;
        width: 20px;
      }
    }

    &.active {
      .line {
        &.line1 {
          transform: rotate(45deg);
          width: 22px;
        }
        &.line2 {
          transform: scaleY(0);
        }
        &.line3 {
          transform: rotate(-45deg);
          width: 22px;
        }
      }
    }
  }

  &.dark {
    @include dark-menu;
  }

  &.light {
    @include light-menu;
  }
}

@include media-breakpoint-down(md) {
  .container {
    padding: 16px 25px;

    .items {
      background-color: $dark-coal;
      bottom: 0;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 54px);
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
      top: -100vh;
      transition: top 0.5s;
      width: 100%;
      z-index: 0;
      .item {
        color: $white;
        margin: 32px 0 !important;
      }
    }

    .hamburgerLines {
      display: flex;
    }

    &.active {
      background-color: $dark-coal;
      @include light-menu;

      .items {
        top: 54px;
      }
    }
  }
}
