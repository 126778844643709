.root {
  :global {
    .c-body.\+white {
      background: none;
      margin-top: 0;
      position: relative;
      padding-bottom: 300px;
    }

    .c-header {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
    }

    .c-header .c-header__link.\+light {
      color: unset;
    }

    .c-main .c-main__heading h2 {
      margin-top: 0;
      opacity: 1;
      transform: none;
      padding-top: 0;
      transition: none;
    }

    .selected-image {
      opacity: 1;
      transform: none;
      padding-top: 0;
      transition: none;
    }

    @media screen and (max-width: 1200px) {
      .c-main .c-main__heading h1 {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .container {
      margin: auto;
      max-width: 1700px;
      position: relative;
    }
    .container--let-talk {
      box-sizing: border-box;
      padding-top: 10.4117647059%;
      display: flex;
      align-items: center;
    }
    .container--main {
      padding-bottom: 16.3058823529%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }

    .container-fluid {
      width: 100%;
    }

    .background {
      overflow: hidden;
    }
    #top-background {
      max-width: 100%;
    }
    #center-background img {
      max-width: 100%;
    }
    #bottom-background {
      max-width: 100%;
    }
    .background__image {
      background-image: url('/images/outlined_diji.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .content {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      text-align: center;
      overflow: hidden;
    }
    .content__item {
      box-sizing: border-box;
    }
    .content__item--bottom {
      color: white;
    }

    .viewport {
      overflow: hidden;
    }

    #nav-button {
      padding: 7px 0;
      cursor: pointer;
      position: relative;
    }
    #nav-button__text {
      color: black;
      display: inline-block;
      font-size: 16px;
      font-weight: 700;
      text-decoration: none;
      text-transform: uppercase;
    }
    #nav-button__line {
      right: 0;
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: black;
      border-radius: 10px;
      transform: translateY(100%);
      bottom: 0;
    }

    #more-on-dribble {
      position: fixed;
      opacity: 0;
      display: none;
      font-size: 120px;
      color: #0f172a;
      margin-bottom: -36px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;
      margin-top: 100px;
      @media screen and (max-width: 1200px) {
        margin-bottom: -24px;
      }
    }

    #go-to-top {
      display: none;
    }

    @media screen and (max-width: 1200px) {
      #more-on-dribble {
        font-size: 80px;
      }
    }
    @media screen and (max-width: 768px) {
      #logo {
        max-width: 120px;
      }

      #nav-button__text {
        font-size: 16px;
      }

      #nav-button__line {
        display: none;
      }

      #go-to-top {
        display: block;
      }

      #more-on-dribble {
        margin-bottom: 0;
      }

      #go-to-top {
        cursor: pointer;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        text-transform: uppercase;
        margin-top: 16px;
        margin-bottom: 32px;
      }

      #selected-works {
        padding-bottom: 150px;
      }
    }
    .font-syne {
      font-family: 'Syne', sans-serif;
    }

    @media screen and (max-width: 768px) {
      .lets-talk__title {
        margin-bottom: 0;
      }
    }
    @media screen and (max-width: 768px) {
      .lets-talk__sub-title {
        margin-bottom: 24px;
      }
    }

    @media screen and (max-width: 768px) {
      .c-input {
        margin-bottom: 16px;
      }

      #main-content.c-main .c-main__heading h1 {
        text-align: left;
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 56px;
      }
      #main-content.c-main .c-main__sub-heading .c-main__sub-heading-right {
        margin: 0;
      }
      #main-content.c-main .c-main__sub-heading .c-main__sub-heading-left {
        margin-top: 40px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
      }

      #selected-works .c-main .c-main__heading {
        margin-top: 60px;
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 56px;
        margin-bottom: 0;
      }
      #selected-works .c-main .c-main__sub-heading {
        margin-top: 0;
        margin-bottom: 64px;
      }

      #more-on-dribble {
        font-size: 32px;
      }
    }
    #contact-form {
      max-width: 436px;
      margin: auto;
    }

    .c-input {
      box-sizing: border-box;
    }

    .image-placeholder {
      background-color: #f6f6f6;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .image-placeholder-lazy-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
    .link-no-style {
      text-decoration: none;
    }
  }
}
