@import 'styles/styles';
.root {
  :global {
    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      border: 0;
      font: inherit;
      font-size: 100%;
      margin: 0;
      padding: 0;
      vertical-align: baseline;
    }

    html {
      height: 100%;
    }

    /* HTML5 display-role reset for older browsers */
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section {
      display: block;
    }

    body {
      line-height: 1;
    }

    ol,
    ul {
      list-style: none;
    }

    blockquote,
    q {
      quotes: none;
    }

    blockquote:before,
    blockquote:after,
    q:before,
    q:after {
      content: '';
      content: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    .c-body {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      font-family: $body-font-family, sans-serif;
      height: 100%;
    }
    .c-body.\+dark {
      background-color: #0f172a;
      background-image: url('/images/background.svg');
      background-size: auto;
      height: 1000px;
      position: relative;
    }
    @media screen and (max-width: 546px) {
      .c-body.\+dark {
        background-image: url('/images/background_mobile.svg');
        height: 720px;
      }
    }
    .c-body.\+light {
      background-color: #22d3ee;
    }
    .c-body.\+white {
      background-color: #f8fafc;
      background-image: url('/images/blue-top.svg');
      background-position-y: -37px;
      background-size: auto;
      position: relative;
      margin-top: -1px;
    }
    @media screen and (max-width: 546px) {
      .c-body.\+white {
        background-image: url('/images/blue-top-mobile.svg');
        background-position-y: -125px;
      }
    }
    .c-body.\+white:before,
    .c-body.\+white:after {
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      z-index: -1;
    }
    @media screen and (max-width: 768px) {
      .c-body.\+white {
        padding-bottom: 15px;
      }
      .c-body.\+white:before {
        height: 45px;
      }
    }
    .c-body.\+white:after {
      background-image: url('/images/grey-bottom.svg');
      background-position: center bottom;
      bottom: 0;
      content: '';
      height: 250px;
      width: 90%;
      z-index: 0;
    }
    @media screen and (max-width: 768px) {
      .c-body.\+white:after {
        height: 84px;
        width: 100%;
      }
    }

    .c-header {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      max-width: 1856px;
      padding: 16px 32px;
      width: 100%;
    }
    .c-header .c-header__link {
      color: #ffffff;
      display: inline-block;
      font-size: 16px;
      font-weight: 700;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
    }
    .c-header .c-header__link:before {
      background-color: #22d3ee;
      bottom: -4px;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
    }
    .c-header .c-header__link.\+dark {
      color: #0f172a;
    }
    .c-header .c-header__link.\+dark:before {
      background-color: #0f172a;
    }
    .c-header .c-header__link.\+light {
      color: #ffffff;
    }
    .c-header .c-header__link.\+light:before {
      background-color: #22d3ee;
    }

    .c-main {
      box-sizing: border-box;
      box-sizing: border-box;
      display: block;
      flex: 1;
      margin: 0 auto;
      margin: 0 auto;
      max-width: 1700px;
      padding: 16px 32px;
      position: relative;
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      .c-header,
      .c-main {
        padding: 16px 25px;
      }
    }
    .c-main.\+light {
      margin-top: 160px;
      padding-bottom: 0;
    }
    .c-main.\+dark {
      max-width: 1140px;
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      .c-main.\+light {
        margin-top: 0;
      }
      .c-main {
        margin: 0;
        max-width: 100%;
      }
    }
    .c-main.\+narrow {
      max-width: 436px;
    }
    @media screen and (max-width: 768px) {
      .c-main.\+narrow {
        margin: 0;
        max-width: 100%;
      }
    }
    .c-main .c-main__heading {
      font-family: $title-font-family, sans-serif;
    }
    .c-main .c-main__heading h1 {
      font-size: 120px;
      margin-bottom: -28px;
      margin-top: 119px;
      transition: font-size 0.3s ease;
    }
    .c-main .c-main__heading.\+bottom {
      bottom: 0;
      left: 0;
      margin: 0 auto;
      overflow: hidden;
      position: sticky;
      right: 0;
      text-align: center;
      z-index: 1;
    }
    .c-main .c-main__heading.\+bottom h1 {
      margin-top: 270px;
    }
    @media screen and (max-width: 768px) {
      .c-main .c-main__heading.\+bottom {
        bottom: -42px;
      }
    }
    @media screen and (max-width: 1200px) {
      .c-main .c-main__heading h1 {
        font-size: 90px;
        margin-bottom: -21px;
        margin-top: 200px;
      }
    }
    @media screen and (max-width: 998px) {
      .c-main .c-main__heading h1 {
        margin-top: 100px;
      }
    }
    @media screen and (max-width: 768px) {
      .c-main .c-main__heading h1 {
        font-size: 40px;
        font-size: 32px;
        line-height: 56px;
        line-height: 42px;
        margin-bottom: 0;
        margin-top: 64px;
      }
    }
    .c-main .c-main__heading h2 {
      font-size: 64px;
      margin-top: 120px;
      text-align: center;
    }
    @media screen and (max-width: 768px) {
      .c-main .c-main__heading h2 {
        font-size: 40px;
        margin-top: 64px;
      }
    }

    .c-main__heading-line-wrapper {
      display: flex;
      gap: 67px;
    }
    @media screen and (max-width: 768px) {
      .c-main__heading-line-wrapper {
        gap: 25px;
      }
    }

    .c-main .c-main__heading-line {
      display: block;
      position: relative;
      width: 100%;
    }
    .c-main .c-main__heading-line:before {
      background-color: #22d3ee;
      border-radius: 50px;
      content: '';
      height: 9px;
      left: 0;
      position: absolute;
      top: 50%;
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      .c-main .c-main__heading-line:before {
        height: 4px;
      }
    }
    .c-main .c-main__sub-heading {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 48px;
    }
    @media screen and (max-width: 998px) {
      .c-main .c-main__sub-heading {
        flex-direction: column-reverse;
        margin-bottom: 64px;
      }
    }
    .c-main .c-main__sub-heading.\+large {
      font-size: 24px;
      justify-content: center;
    }
    @media screen and (max-width: 768px) {
      .c-main .c-main__sub-heading.\+large {
        font-size: 18px;
      }
    }
    .c-main .c-main__sub-heading .c-main__sub-heading-left {
      flex-grow: 0;
      flex-shrink: 0;
      font-size: 24px;
      line-height: 32px;
      margin-right: 16px;
      text-align: right;
      width: 470px;
    }
    @media screen and (max-width: 998px) {
      .c-main .c-main__sub-heading .c-main__sub-heading-left {
        margin-right: auto;
        max-width: 450px;
        text-align: left;
        width: 100%;
      }
    }
    @media screen and (max-width: 768px) {
      .c-main .c-main__sub-heading .c-main__sub-heading-left {
        font-size: 18px;
      }
    }
    .c-main .c-main__sub-heading .c-main__sub-heading-left p {
      font-weight: 400;
    }
    .c-main .c-main__sub-heading .c-main__sub-heading-right {
      font-family: $title-font-family, sans-serif;
      font-size: 120px;
      text-align: right;
      transition: font-size 0.3s ease;
      width: 100%;
    }
    @media screen and (max-width: 1200px) {
      .c-main .c-main__sub-heading .c-main__sub-heading-right {
        font-size: 90px;
      }
    }
    .c-main .c-main__sub-heading .c-main__sub-heading-right p {
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      .c-main .c-main__sub-heading .c-main__sub-heading-right {
        align-self: flex-end;
        font-size: 40px;
        margin: 32px 0;
      }
    }

    .c-button-container {
      display: flex;
    }
    .c-button-container.\+align-right {
      justify-content: flex-end;
    }
    @media screen and (max-width: 768px) {
      .c-button-container.\+align-right {
        justify-content: center;
      }
    }

    .c-button {
      border: 1px solid #ffffff;
      border-radius: 50px;
      color: #ffffff;
      cursor: pointer;
      font-family: $body-font-family, sans-serif;
      line-height: 30px;
      padding: 10px 32px;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;
      gap: 8px;
    }
    .c-button.\+centered {
      display: flex;
      margin: 0 auto;
      width: fit-content;
    }
    .c-button.\+dark {
      background: #0f172a;
      border: none;
    }

    @media screen and (min-width: 768px) {
      .c-input-group {
        display: flex;
        justify-content: space-between;
      }
      .c-input-group .c-input:first-of-type {
        margin-right: 8px;
      }
      .c-input-group .c-input:last-of-type {
        margin-left: 8px;
      }
    }

    .c-input {
      background: none;
      border-bottom: 1px solid rgba(15, 23, 42, 0.25);
      border-left: none;
      border-right: none;
      border-top: none;
      color: #0f172a;
      display: block;
      font-family: $body-font-family, sans-serif;
      font-size: 18px;
      margin-bottom: 24px;
      padding: 10.66667px 4px;
      width: 100%;
      &.error {
        border-bottom: solid 1px red;
      }
    }
    .c-input::placeholder {
      color: rgba(15, 23, 42, 0.25);
    }
    .c-input.\+active {
      border-bottom: 1px solid #0f172a;
    }

    .selected-image-container {
      /* height: 4956px; */
      position: relative;
    }
    .selected-image {
      margin-top: 50px;
      opacity: 0;
      transform: translateY(50px);
      /* padding-top: 50px; */
      transition: transform 0.8s ease;
    }

    .selected-image .image {
      display: block;
      margin-bottom: 32px;
      width: 100%;
    }
    .selected-image .image-box {
      display: block;
      border-radius: 10px;
      height: 0;
      margin-bottom: 0;
      overflow: hidden;
      position: relative;
    }
    .selected-image img {
      height: auto;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    .selected-image {
      animation-delay: 1000ms;
      width: 100%;
    }
    .selected-image.\+1 .image-box {
      padding-top: 75.3%;
    }
    .selected-image.\+2 .image-box {
      padding-top: 75%;
    }
    .selected-image.\+3 .image-box {
      padding-top: 75%;
    }
    .selected-image.\+4 .image-box {
      padding-top: 74.6%;
    }
    .selected-image.\+5 .image-box {
      padding-top: 75.11%;
    }
    .selected-image.\+6 .image-box {
      padding-top: 75.27%;
    }
    .selected-image.\+7 .image-box {
      padding-top: 75.05%;
    }
    .selected-image.\+8 .image-box {
      padding-top: 75.15%;
    }
    .selected-image.\+9 .image-box {
      padding-top: 75.05%;
    }
    .selected-image.\+10 .image-box {
      padding-top: 75.27%;
    }
    .selected-image.\+11 .image-box {
      padding-top: 74.45%;
    }
    .selected-image.\+12 {
      max-width: 655px;
    }
    .selected-image.\+12 .image-box {
      padding-top: 75.12%;
    }
    @media screen and (min-width: 768px) {
      .selected-image-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 1140px;
      }
      .selected-image-wrapper {
        display: grid;
        position: relative;
      }

      .selected-image.\+1 {
        margin-left: auto;
        max-width: 558px;
      }
      .selected-image.\+2 {
        max-width: 461px;
      }
      .selected-image.\+2 .image-box {
        margin-top: 34%;
      }
      .selected-image.\+3 {
        margin: 0 auto;
        max-width: 364px;
      }
      .selected-image.\+4 {
        margin-left: auto;
        max-width: 364px;
      }
      .selected-image.\+5 .image-box {
        margin-top: 11.5%;
      }
      .selected-image.\+5 {
        max-width: 655px;
      }
      .selected-image.\+6 {
        margin-left: auto;
        max-width: 558px;
      }
      .selected-image.\+7 {
        max-width: 461px;
      }
      .selected-image.\+7 .image-box {
        margin-top: 40.9%;
      }
      .selected-image.\+8 {
        margin-left: auto;
        max-width: 849px;
      }
      .selected-image.\+9 {
        margin: auto auto auto 0;
        max-width: 461px;
      }
      .selected-image.\+10 {
        max-width: 558px;
      }
      .selected-image.\+11 {
        margin: 0 auto;
        max-width: 364px;
      }

      .selected-image-wrapper.\+1 {
        gap: min(10%, 121px);
        grid-template-columns: 49fr 40.4fr;
      }
      .selected-image-wrapper.\+2 {
        margin-top: 180px;
      }
      .selected-image-wrapper.\+3 {
        gap: min(10%, 121px);
        grid-template-columns: 31.9fr 57.46fr;
        margin-top: 120px;
      }
      .selected-image-wrapper.\+4 {
        gap: min(10%, 121px);
        grid-template-columns: 48.95fr 40.44fr;
        margin-top: 180px;
      }
      .selected-image-wrapper.\+5 {
        margin-top: 180px;
      }
      .selected-image-wrapper.\+6 {
        gap: min(10%, 121px);
        grid-template-columns: 40.44fr 48.95fr;
        margin-top: 180px;
      }
      .selected-image-right {
        display: grid;
        gap: min(180px, 20.66%);
        grid-template-rows: 1fr 1fr;
      }
      .selected-image-wrapper.\+7 {
        margin-top: 180px;
      }
      .selected-image.\+12 {
        margin: 0 auto;
      }
    }

    .u-marg-bottom {
      margin-bottom: 16px;
    }

    .u-marg-bottom-large {
      margin-bottom: 32px;
    }

    .u-align-center {
      text-align: center;
    }

    .u-white {
      color: #ffffff;
    }

    .u-navy {
      color: #0f172a;
    }

    .u-neon {
      color: #22d3ee;
    }

    .words-wrapper {
      display: inline-block;
      position: relative;
      text-align: left;
    }

    .words-wrapper p {
      display: inline-block;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      white-space: nowrap;
    }
    .words-wrapper .is-visible {
      opacity: 1;
      position: relative;
      :local {
        -webkit-animation: push-in 0.6s;
        -moz-animation: push-in 0.6s;
        animation: push-in 0.6s;
      }
    }
    .words-wrapper .is-hidden {
      :local {
        -webkit-animation: push-out 0.6s;
        -moz-animation: push-out 0.6s;
        animation: push-out 0.6s;
      }
    }
    .go-to-top {
      background-color: transparent;
      border: 0;
      display: none;
      font-size: 14px;
      font-weight: bold;
      margin-top: 10px;
      text-align: center;
      width: 100%;
    }

    @media only screen and (max-width: 768px) {
      .go-to-top {
        display: block;
      }
    }

    @-webkit-keyframes push-in {
      0% {
        opacity: 0;
        -webkit-transform: translateY(100%);
      }
      70% {
        opacity: 1;
        -webkit-transform: translateY(-10%);
      }
      100% {
        opacity: 1;
        -webkit-transform: translateY(0);
      }
    }
    @-moz-keyframes push-in {
      0% {
        opacity: 0;
        -moz-transform: translateY(100%);
      }
      60% {
        opacity: 1;
        -moz-transform: translateY(-10%);
      }
      100% {
        opacity: 1;
        -moz-transform: translateY(0);
      }
    }
    @keyframes push-in {
      0% {
        opacity: 0;
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
        -o-transform: translateY(100%);
        transform: translateY(100%);
      }
      60% {
        opacity: 1;
        -webkit-transform: translateY(-10%);
        -moz-transform: translateY(-10%);
        -ms-transform: translateY(-10%);
        -o-transform: translateY(-10%);
        transform: translateY(-10%);
      }
      100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
      }
    }
    @-webkit-keyframes push-out {
      0% {
        opacity: 1;
        -webkit-transform: translateY(0);
      }
      60% {
        opacity: 0;
        -webkit-transform: translateY(-110%);
      }
      100% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
      }
    }
    @-moz-keyframes push-out {
      0% {
        opacity: 1;
        -moz-transform: translateY(0);
      }
      60% {
        opacity: 0;
        -moz-transform: translateY(-110%);
      }
      100% {
        opacity: 0;
        -moz-transform: translateY(-100%);
      }
    }
    @keyframes push-out {
      0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
      }
      60% {
        opacity: 0;
        -webkit-transform: translateY(-110%);
        -moz-transform: translateY(-110%);
        -ms-transform: translateY(-110%);
        -o-transform: translateY(-110%);
        transform: translateY(-110%);
      }
      100% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%);
      }
    }

    .fadein {
      animation-duration: 350ms;
      animation-fill-mode: both;
      animation-name: fadein;
      animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
    }

    @keyframes fadein {
      0% {
        opacity: 0;
        transform: translateY(50%);
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
